import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';
import 'zone.js/dist/zone-error';

export const cigkoftemCustomerGroup = 'cigkoftem';
export const customerGroup = cigkoftemCustomerGroup;

const supportEmail = {
	cigkoftem: 'cigkoftem@smoothr.de'
};
const firebaseConfig = {
	cigkoftem: {
		apiKey: 'AIzaSyDhL-ZYeC4HKwBPB-qZoVRlpkiGFsixaKI',
		authDomain: 'cigkoftem-web-app.firebaseapp.com',
		projectId: 'cigkoftem-web-app',
		storageBucket: 'cigkoftem-web-app.appspot.com',
		messagingSenderId: '722853449331',
		appId: '1:722853449331:web:5a91c9d50bbc23ac90a406',
		measurementId: 'G-N2RK1HSK19'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyA4wKblM1tAmbRIW1QwL0WRnJKB6_vn4pY',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment
};
