import { PreorderType } from './../../../smoothr-web-app-core/enums/PreorderType';
import {Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-order-page-toolbar',
	templateUrl: './order-page-toolbar.component.html',
	styleUrls: ['order-page-toolbar.component.scss']
})
export class OrderPageToolbarComponent implements OnInit {
	@Output()
	backClick = new EventEmitter<void>();
	@Output()
	close = new EventEmitter<void>();
	@Input()
	showBlack = false;
	@Input()
	title: string;
	@Input()
	preorderType: PreorderType | string;
	@Input()
	hideElements: boolean;
	@Input()
	showCross = false;
	environment = environment;
	pt = PreorderType;
	constructor() {}

	ngOnInit() {}

	onBackClick() {
		this.backClick.emit();
	}
	onClose() {
		this.close.emit();
	}
	openSelectTableModal() {

	}
}
