import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const cigkoftemCustomerGroup = 'cigkoftem';
export const customerGroup = cigkoftemCustomerGroup;

const supportEmail = {
	cigkoftem: 'cigkoftem@smoothr.de'
};
const firebaseConfig = {
	cigkoftem: {
		apiKey: 'AIzaSyDhL-ZYeC4HKwBPB-qZoVRlpkiGFsixaKI',
		authDomain: 'cigkoftem-web-app.firebaseapp.com',
		projectId: 'cigkoftem-web-app',
		storageBucket: 'cigkoftem-web-app.appspot.com',
		messagingSenderId: '722853449331',
		appId: '1:722853449331:web:d406cea4458b276790a406',
		measurementId: 'G-PYVY00LHBS'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyApJ01EreIBE15iPDkUcsN9jhTdCx6G9vg',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
